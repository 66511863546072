import { FC, InputHTMLAttributes, ReactNode, useState } from 'react';
import * as Icon from '../icons';

interface InputWordProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label: string;
  type: string;
  mandatory?: boolean;
  tooltip?: boolean;
  placeholder: string;
  actionLabel?: ReactNode;
  value: string | number;
  onChangeValue: (e: string) => void;
  errorMessage?: string | ReactNode;
}

export const InputWord: FC<InputWordProps> = ({
  label,
  type,
  mandatory = false,
  tooltip = false,
  placeholder,
  actionLabel,
  value,
  onChangeValue,
  errorMessage,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  const [tooltipTextOpen, setTooltipTextOpen] = useState(false);

  return (
    <>
      <div className='relative flex justify-between pb-2 pt-4 font-medium'>
        {tooltipTextOpen && (
          <span className='absolute bottom-8 left-[-6.5rem] rounded-md bg-[#4F5254] p-1 text-center text-xs leading-5 text-white opacity-90'>
            L&apos;utilisation d&apos;un identifiant de connexion assure une sécurité accrue.
            <br /> Votre adresse email est ainsi mieux protégée.
          </span>
        )}
        <span className='text-black-default flex'>
          {label} {mandatory && '*'}{' '}
          {tooltip && (
            <Icon.Info
              onMouseEnter={() => setTooltipTextOpen(true)}
              onMouseLeave={() => setTooltipTextOpen(false)}
              className='ml-2 mt-1 max-md:hidden'
            />
          )}
        </span>
        {actionLabel}
      </div>
      <div className='relative'>
        <input
          type={inputType}
          placeholder={placeholder}
          className={`inline w-full rounded-lg border-[1px] bg-white-light p-3 hover:border-green-primary hover:bg-[#57CC991A] focus:border-[2px] focus:border-green-primary focus-visible:outline-0 ${errorMessage ? 'border-red-primary' : 'border-gray-secondary'} `}
          value={value}
          onChange={e => onChangeValue(e.target.value)}
          {...props}
        />
        {type === 'password' && !errorMessage && (
          <>
            {inputType === 'password' ? (
              <Icon.Eye className='absolute right-4 top-3.5 cursor-pointer' onClick={() => setInputType('text')} />
            ) : (
              <Icon.EyeCrossed className='absolute right-4 top-3.5 cursor-pointer' onClick={() => setInputType('password')} />
            )}
          </>
        )}
        {errorMessage && <Icon.Error className='absolute right-4 top-4' />}
      </div>
      {errorMessage && <div className='pt-2 font-medium text-red-primary'>{errorMessage}</div>}
    </>
  );
};

interface InputCheckboxProps {
  label: ReactNode;
  checked: boolean;
  color?: string;
  actionOnClick?: () => void;
  errorMessage?: string | ReactNode;
}

export const InputCheckbox: FC<InputCheckboxProps> = ({ label, checked, color = '#57CC99', actionOnClick, errorMessage }) => {
  const [checkBorderColor, setCheckBorderColor] = useState('#DCE2EC');

  return (
    <>
      <div className='flex pt-6'>
        {checked ? (
          <Icon.CheckboxChecked color={color} onClick={actionOnClick} className='cursor-pointer' />
        ) : (
          <Icon.CheckboxUnchecked
            onMouseEnter={() => setCheckBorderColor(color === '#57CC99' ? '#38A3A5' : color)}
            onMouseLeave={() => setCheckBorderColor('#DCE2EC')}
            borderColor={errorMessage ? '#D82F2F' : checkBorderColor}
            onClick={actionOnClick}
            className='cursor-pointer'
          />
        )}
        <span className='ml-3'>{label}</span>
      </div>
      {errorMessage && <div className='pt-2 font-medium text-red-primary'>{errorMessage}</div>}
    </>
  );
};
