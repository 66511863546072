import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { updatePasswordSchema, updatePasswordType } from '../../schema/DashboardSchema';
import UsersService from '../../services/users.service';
import { ToastFunc, ToastQueue } from '../../components/common/Toast';
import { InputWord } from '../../components/common/Input';
import { DashboardButton } from '../../components/common/Button';
import { refreshToken } from '../../services/token';
import AuthSettingsItemHeader from '../../components/common/AuthSettingsItemHeader';
import { PasswordConstraint } from '../../components/common/PasswordConstraint';

const DashboardAuthenticationPasswordComponent = () => {
  const navigate = useNavigate();
  const usersService = new UsersService();
  const toastQueueRef = useRef<ToastFunc>();

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const updatePasswordMethods = useForm<updatePasswordType>({
    resolver: yupResolver(updatePasswordSchema),
    mode: 'onSubmit',
    defaultValues: {
      actualPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const { watch } = updatePasswordMethods;

  const updatePassword = async ({ actualPassword, confirmNewPassword }: updatePasswordType) => {
    try {
      await usersService
        .updatePassword({
          oldPassword: actualPassword,
          newPassword: confirmNewPassword,
        })
        .then(() =>
          toastQueueRef?.current?.addToast({
            type: 'SUCCESS',
            description: 'Mot de passe mis à jour',
            timer: 5000,
          }),
        );

      navigate('/dashboard/authentication');
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 401) {
        refreshToken();
      }
      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      }
    }
  };

  return (
    <>
      <ToastQueue ref={toastQueueRef} />
      <AuthSettingsItemHeader
        title='Modifier votre mot de passe'
        description='Pour votre sécurité, votre mot de passe doit être strictement personnel. Ne le divulguez à personne.'
      />
      <FormProvider {...updatePasswordMethods}>
        <form onSubmit={updatePasswordMethods.handleSubmit(updatePassword)}>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='actualPassword'
              control={updatePasswordMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Mot de passe actuel'
                    type='password'
                    placeholder='Mot de passe'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='newPassword'
              control={updatePasswordMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Nouveau mot de passe'
                    type='password'
                    placeholder='Mot de passe'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <PasswordConstraint password={watch('newPassword')} />
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='confirmNewPassword'
              control={updatePasswordMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Confirmer le nouveau mot de passe'
                    type='password'
                    placeholder='Mot de passe'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='mt-6 flex justify-end'>
            <DashboardButton type='cancel' onClick={() => navigate('/dashboard/authentication')} />
            <DashboardButton type='confirm' />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default DashboardAuthenticationPasswordComponent;
