import * as Yup from 'yup';

export const connexionSchema = Yup.object({
  nickNameOrEmail: Yup.string().default('').required('Ce champ est obligatoire'),
  password: Yup.string().default('').required('Ce champ est obligatoire'),
});
export type connexionType = Yup.InferType<typeof connexionSchema>;

export const connexionStepTwoSchema = Yup.object({
  code: Yup.string().default('').required('Ce champ est obligatoire'),
});
export type connexionStepTwoType = Yup.InferType<typeof connexionStepTwoSchema>;

export const updateSkemeetPasswordSchema = Yup.object({
  password: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?=.{12,})/,
      "Votre mot de passe doit contenir 12 caractères minimum, ainsi qu'au moins 1 de chacun de ces éléments : lettre minuscule, lettre majuscule, chiffre, caractère spécial.",
    ),
  confirmPassword: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .oneOf([Yup.ref('password')], 'Les mots de passe ne correspondent pas'),
});
export type updateSkemeetPasswordType = Yup.InferType<typeof updateSkemeetPasswordSchema>;
