import { FC, ReactElement } from 'react';
import * as Icon from '../icons';
import { OutletProps } from 'react-router-dom';

interface LayoutProps {
  Outlet: (props: OutletProps) => ReactElement | null;
}

const Layout: FC<LayoutProps> = ({ Outlet }) => {
  return (
    <>
      <div className='absolute left-16 top-52 w-[calc(100%-4rem)] max-lg:hidden max-xl:top-[21rem]'>
        <Icon.LoginBackground className='h-full w-full' />
      </div>
      <div className='flex h-full max-lg:bg-white-light'>
        <div className='w-[40%] px-8 pt-5 text-green-dark max-lg:hidden'>
          <div className='mb-4 flex justify-end text-sm'>
            par <Icon.Skezi className='ml-2 mt-[0.2rem]' />
          </div>
          <div className='text-4xl font-bold'>
            <div>Créer,</div>
            <div className='my-2'>Collaborer,</div>
            <div>Collecter...</div>
          </div>
          <div className='my-4 text-lg'>
            Un espace de connexion <span className='font-bold'>pour toutes nos solutions !</span>
          </div>
          <div className='mt-20 flex'>
            <div className='mr-12'>
              <Icon.Skezia />
              <div className='pt-2 text-sm'>Solution de collecte de données sensibles.</div>
            </div>
            <div>
              {/* <Icon.Skemeet /> */}
              <Icon.Skemeet />
              <div className='pt-2 text-sm'>Solution de télé-expertise.</div>
            </div>
          </div>
        </div>
        <div className='flex min-h-screen w-[60%] flex-col bg-white-light px-10 max-lg:w-[100%] max-lg:pb-5 min-lg:items-center min-lg:justify-center min-xl:px-28'>
          <div className='flex w-full flex-col py-6 min-lg:hidden'>
            <div className='flex justify-center'>
              <img src='/Skezi.jpg' className='w-[9rem] text-center' />
            </div>
            <div className='my-4 flex justify-center'>
              <span className='w-[14rem] text-center'>Un espace de connexion pour toutes nos solutions.</span>
            </div>
            <div className='flex justify-center'>
              <Icon.SkeziaMobile />
              <span className='relative'>
                <Icon.SkemeetMobile />
              </span>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
