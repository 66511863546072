import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { updateEmailSchema, updateEmailType } from '../../schema/DashboardSchema';
import { DashboardButton } from '../../components/common/Button';
import { InputWord } from '../../components/common/Input';
import { ToastFunc, ToastQueue } from '../../components/common/Toast';
import UsersService from '../../services/users.service';
import AuthSettingsItemHeader from '../../components/common/AuthSettingsItemHeader';
import { refreshToken } from '../../services/token';

const DashboardAuthenticationEmailComponent = () => {
  const navigate = useNavigate();
  const usersService = new UsersService();
  const toastQueueRef = useRef<ToastFunc>();

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const updateEmailMethods = useForm<updateEmailType>({
    resolver: yupResolver(updateEmailSchema),
    mode: 'onSubmit',
    defaultValues: {
      newEmail: '',
      confirmNewEmail: '',
    },
  });

  const confirmUpdateEmail = async ({ confirmNewEmail }: updateEmailType) => {
    try {
      await usersService.updateEmail({
        email: confirmNewEmail,
      });

      toastQueueRef?.current?.addToast({
        type: 'SUCCESS',
        description: 'Email mis à jour',
        timer: 5000,
      });

      setTimeout(() => {
        navigate('/dashboard/authentication');
      }, 5000);
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 401) {
        refreshToken();
      }
      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: "L'adresse e-mail est déjà utilisée",
          type: 'ERROR',
        });
      }
    }
  };

  return (
    <>
      <ToastQueue ref={toastQueueRef} />
      <AuthSettingsItemHeader title='Modifier votre adresse e-mail' description='Modifier votre adresse e-mail de connexion' />
      <FormProvider {...updateEmailMethods}>
        <form onSubmit={updateEmailMethods.handleSubmit(confirmUpdateEmail)}>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='newEmail'
              control={updateEmailMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Nouvelle adresse e-mail'
                    type='text'
                    placeholder='exemple@email.com'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='confirmNewEmail'
              control={updateEmailMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Confirmer la nouvelle adresse e-mail'
                    type='text'
                    placeholder='exemple@email.com'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='mt-6 flex justify-end'>
            <DashboardButton type='cancel' onClick={() => navigate('/dashboard/authentication')} />
            <DashboardButton type='confirm' />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default DashboardAuthenticationEmailComponent;
