import React, { FC, SVGProps } from 'react';

export const SkemeetMobile: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={44} height={43} viewBox='0 0 44 43' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Skemeet'>
        <g id='Vector' filter='url(#filter0_d_18478_107415)'>
          <path
            d='M21.0137 38C30.6863 38 38.5274 30.165 38.5274 20.5C38.5274 10.835 30.6863 3 21.0137 3C11.3412 3 3.5 10.835 3.5 20.5C3.5 30.165 11.3412 38 21.0137 38Z'
            fill='white'
          />
        </g>
        <g id='Group 14116'>
          <path
            id='Vector_2'
            d='M21.591 12.7454H12.9556C12.3994 12.7454 11.9745 12.8716 11.6818 13.1232C11.3881 13.3749 11.2422 13.7181 11.2422 14.1531C11.2422 14.588 11.383 14.9105 11.6654 15.1656C11.9469 15.4216 12.3779 15.5487 12.9565 15.5487H16.6589V27.7347C16.6589 28.4291 16.8169 28.9479 17.133 29.2912C17.4491 29.6344 17.8584 29.8065 18.3619 29.8065C18.8654 29.8065 19.261 29.637 19.5736 29.2972C19.8862 28.9583 20.0425 28.4369 20.0425 27.7356V15.5487H21.5928V12.7454H21.591Z'
            fill='#214152'
          />
          <g id='Group'>
            <path
              id='Vector_3'
              d='M25.7614 12.3025C25.8495 11.3635 26.6726 11.1291 27.1294 11.1508L29.07 11.1611C29.7721 11.1611 30.3931 11.6229 30.3931 12.3025L30.3853 14.4651C30.3853 15.1473 29.7808 15.5762 29.0752 15.5762L28.7833 15.5892V15.7812L28.7721 16.6242C28.7721 16.6277 28.7746 16.6268 28.7721 16.6242L28.0665 15.9204L27.7469 15.5788L27.6044 15.5762L27.031 15.5952C26.7451 15.6048 26.4575 15.5252 26.2373 15.3488C25.8746 15.0583 25.7243 14.5784 25.7399 14.0423L25.7606 12.3025H25.7614Z'
              fill='#3BC3A8'
            />
            <path
              id='Vector_4'
              d='M26.8712 15.5918C26.8133 15.5918 26.7615 15.5823 26.7157 15.565C26.663 15.5572 26.6121 15.5408 26.5663 15.5166C26.4903 15.4863 26.4307 15.4353 26.3893 15.373C26.3651 15.3428 26.3444 15.309 26.3297 15.2727C26.2131 15.1171 26.2122 14.8802 26.3279 14.7237C26.3064 14.3544 26.3858 14.0034 26.4031 13.6281C26.4152 13.3739 26.4497 13.1015 26.4324 12.8481C26.4307 12.817 26.4264 12.785 26.4212 12.7548C26.3573 12.7202 26.2994 12.664 26.2563 12.581C26.2468 12.5628 26.2381 12.5438 26.2286 12.5247C26.2312 12.338 26.2381 12.1512 26.2563 11.9653C26.2589 11.9419 26.2623 11.9203 26.2675 11.8987C26.2278 11.8901 26.188 11.884 26.1492 11.878C26.1518 11.8728 26.1526 11.8676 26.1552 11.8633C26.0559 11.8624 25.9514 11.8641 25.8452 11.8667L25.3857 11.8693C25.3857 11.8693 25.3857 11.8693 25.3857 11.8702L23.7327 11.8797C23.0306 11.8797 22.4097 12.3414 22.4097 13.0211L22.4174 15.1837C22.4174 15.8659 23.022 16.2948 23.7276 16.2948L24.0195 16.3078V16.4997L24.0307 17.3428L24.7363 16.6389L25.0558 16.2974L25.1983 16.2948L26.0041 16.287C26.4601 16.1928 26.752 15.9394 26.9118 15.5927C26.9092 15.5927 26.9074 15.5909 26.9048 15.5901C26.8936 15.5901 26.8833 15.5918 26.8712 15.5918Z'
              fill='#214152'
            />
            <path
              id='Vector_5'
              d='M27.4149 13.1749H26.6402V12.3863C26.6402 12.101 26.4131 11.8701 26.1333 11.8701C25.8535 11.8701 25.6263 12.101 25.6263 12.3863V13.1749H24.8517C24.5719 13.1749 24.3447 13.4058 24.3447 13.6912C24.3447 13.9765 24.5719 14.2074 24.8517 14.2074H25.6263V14.996C25.6263 15.2813 25.8535 15.5122 26.1333 15.5122C26.4131 15.5122 26.6402 15.2813 26.6402 14.996V14.2074H27.4149C27.6947 14.2074 27.9219 13.9765 27.9219 13.6912C27.9219 13.4058 27.6947 13.1749 27.4149 13.1749Z'
              fill='white'
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id='filter0_d_18478_107415'
          x={0.5}
          y={0}
          width={43.0273}
          height={43}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx={1} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_18478_107415' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_18478_107415' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};
