import { FC } from 'react';

interface BasicButtonProps {
  label: string;
  fullWidth: boolean;
  template?: string;
  isSubmitButton?: boolean;
  onClick?: () => void;
}

export const BasicButton: FC<BasicButtonProps> = ({ label, fullWidth, template = 'green', isSubmitButton = false, onClick }) => {
  return (
    <>
      <button
        type={isSubmitButton ? 'submit' : 'button'}
        className={`mt-8 rounded-lg py-2 font-semibold ${template === 'green' && 'bg-green-primary text-white hover:bg-green-secondary'} ${template === 'white' && 'border-2 border-green-primary bg-white'} ${fullWidth ? 'w-full' : 'px-5'} `}
        onClick={onClick}
      >
        {label}
      </button>
    </>
  );
};

interface DashboardButtonProps {
  type: string;
  onClick?: () => void;
}

export const DashboardButton: FC<DashboardButtonProps> = ({ type, onClick }) => {
  return (
    <>
      <button
        type={type === 'confirm' ? 'submit' : 'button'}
        className={`ml-3 rounded-full px-5 py-3 font-semibold ${type === 'confirm' && 'bg-[#F6A436] text-white'} ${type === 'cancel' && 'border-2 border-[#F6A436] text-[#F6A436]'} `}
        onClick={onClick}
      >
        {type === 'confirm' && 'Confirmer'}
        {type === 'cancel' && 'Annuler'}
      </button>
    </>
  );
};
