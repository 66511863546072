import { FC, useEffect, useState } from 'react';
import * as Icon from '../icons';

interface PasswordConstraint {
  password: string;
}

export const PasswordConstraint: FC<PasswordConstraint> = ({ password }) => {
  const [passwordProgressBar, setPasswordProgressBar] = useState<number>(0);
  const [passwordTwelveCharacters, setPasswordTwelveCharacters] = useState<boolean>(false);
  const [passwordOneLowerCaseCharacter, setPasswordOneLowerCaseCharacter] = useState<boolean>(false);
  const [passwordOneUpperCaseCharacter, setPasswordOneUpperCaseCharacter] = useState<boolean>(false);
  const [passwordOneNumberCharacter, setPasswordOneNumberCharacter] = useState<boolean>(false);
  const [passwordOneSpecialCharacter, setPasswordOneSpecialCharacter] = useState<boolean>(false);

  useEffect(() => {
    let progressBar = 0;

    if (password?.length >= 12) {
      setPasswordTwelveCharacters(true);
      progressBar += 20;
    } else {
      setPasswordTwelveCharacters(false);
    }

    if (/[a-z]/.test(password)) {
      setPasswordOneLowerCaseCharacter(true);
      progressBar += 20;
    } else {
      setPasswordOneLowerCaseCharacter(false);
    }

    if (/\W/.test(password)) {
      setPasswordOneSpecialCharacter(true);
      progressBar += 20;
    } else {
      setPasswordOneSpecialCharacter(false);
    }

    if (/[A-Z]/.test(password)) {
      setPasswordOneUpperCaseCharacter(true);
      progressBar += 20;
    } else {
      setPasswordOneUpperCaseCharacter(false);
    }

    if (/\d/.test(password)) {
      setPasswordOneNumberCharacter(true);
      progressBar += 20;
    } else {
      setPasswordOneNumberCharacter(false);
    }

    setPasswordProgressBar(progressBar);
  }, [password]);

  return (
    <div className='mt-4 flex flex-col gap-y-4'>
      <span className='text-xs'>Votre mot de passe doit avoir :</span>
      <div className='flex'>
        <div className={`flex w-1/3 items-center gap-x-2.5 text-xs ${passwordTwelveCharacters ? 'text-black' : 'text-gray-primary'}`}>
          {passwordTwelveCharacters ? <Icon.Right /> : <Icon.Wrong />}
          <span>12 caractères</span>
        </div>
        <div className={`flex w-1/3 items-center gap-x-2.5 text-xs ${passwordOneLowerCaseCharacter ? 'text-black' : 'text-gray-primary'}`}>
          {passwordOneLowerCaseCharacter ? <Icon.Right /> : <Icon.Wrong />}
          <span>1 minuscule</span>
        </div>
        <div className={`flex w-1/3 items-center gap-x-2.5 text-xs ${passwordOneSpecialCharacter ? 'text-black' : 'text-gray-primary'}`}>
          {passwordOneSpecialCharacter ? <Icon.Right /> : <Icon.Wrong />}
          <span>1 caractère spécial</span>
        </div>
      </div>
      <div className='flex'>
        <div className={`flex w-1/3 items-center gap-x-2.5 text-xs ${passwordOneUpperCaseCharacter ? 'text-black' : 'text-gray-primary'}`}>
          {passwordOneUpperCaseCharacter ? <Icon.Right /> : <Icon.Wrong />}
          <span>1 majuscule</span>
        </div>
        <div className={`flex w-2/3 items-center gap-x-2.5 text-xs ${passwordOneNumberCharacter ? 'text-black' : 'text-gray-primary'}`}>
          {passwordOneNumberCharacter ? <Icon.Right /> : <Icon.Wrong />}
          <span>1 chiffre</span>
        </div>
      </div>
      <div className='flex flex-col gap-y-2.5'>
        <span className='w-full text-xs'>
          {'Sécurité'} : {passwordProgressBar < 50 ? 'Faible' : passwordProgressBar < 80 ? 'Moyenne' : 'Forte'}
        </span>
        <div
          className='h-2 w-full rounded-full'
          style={{
            background: `linear-gradient(to right, #38A3A5, #38A3A5 ${passwordProgressBar}%, #DCE2EC ${passwordProgressBar}%, #DCE2EC)`,
          }}
        />
        <span className='text-xs text-gray-primary'>
          Evitez les mots de passe qui peuvent être facilement devinés ou que vous utilisez sur d’autres sites
        </span>
      </div>
    </div>
  );
};
