import { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Seo } from '../../components/common/Seo';
import { ToastFunc, ToastQueue } from '../../components/common/Toast';
import ErrorForm from '../../components/forms/ErrorForm';
import NicknameRequestForm from './forms/NicknameRequestForm';
import NicknameResetForm from './forms/NicknameResetForm';
import NicknameRequestSent from './NicknameRequestSent';
import NicknameResetSuccess from './NicknameResetSuccess';

const ResetNicknameComponent = () => {
  const toastQueueRef = useRef<ToastFunc>();
  const navigate = useNavigate();
  const searchQueryParams = useSearchParams()[0];
  const resetNicknameToken = searchQueryParams.get('resetNicknameToken');
  const [pageStep, setPageStep] = useState(resetNicknameToken ? 'emailVerified' : 'request');
  const redirectUrl = searchQueryParams.get('redirectUrl');

  return (
    <>
      <ToastQueue ref={toastQueueRef} />
      <div className={`z-[10] w-full rounded-lg bg-white p-6`}>
        {
          {
            request: <NicknameRequestForm setPageStep={setPageStep} toastQueueRef={toastQueueRef} redirectUrl={redirectUrl} />,
            emailSend: <NicknameRequestSent />,
            emailVerified: (
              <NicknameResetForm
                setPageStep={setPageStep}
                resetNicknameToken={resetNicknameToken}
                toastQueueRef={toastQueueRef}
                redirectUrl={redirectUrl}
              />
            ),
            resetNicknameSuccess: <NicknameResetSuccess redirectUrl={redirectUrl} />,
            error: <ErrorForm onClick={() => navigate(redirectUrl ? `/login?redirectUrl=${redirectUrl}` : `/login`)} />,
          }[pageStep]
        }
      </div>
      <Seo title={'Réinitialiser votre identifiant'} />
    </>
  );
};

export default ResetNicknameComponent;
