import { format } from 'date-fns';
import * as Icon from '../../../components/icons';

interface AuthSettingsItemProps {
  title: string;
  description: string;
  fieldType: 'UPDATED' | 'ACTIVATE';
  actionDate: Date;
  borderBottom?: boolean;
  actionOnClick: () => void;
}

const AuthSettingsItem = ({ title, description, fieldType, actionDate, borderBottom = false, actionOnClick }: AuthSettingsItemProps) => {
  return (
    <div className={`py-4 ${borderBottom && 'border-b border-b-[#DCE2EC]'}`} onClick={actionOnClick}>
      <div className='flex cursor-pointer rounded-lg px-4 py-4 hover:bg-[#57CC991A]'>
        <div className='gap-6 max-md:w-[95%] min-md:w-[65%]'>
          <div className='text-lg font-bold text-[#21577A]'>{title}</div>
          <div className='pt-2.5 text-[#21577A]'>{description}</div>
        </div>
        {actionDate && (
          <div className='my-auto ml-auto px-12 text-center text-xs text-[#21577A]'>
            {fieldType === 'UPDATED' ? 'Mis à jour le' : 'Activé le'} {format(actionDate, 'dd/MM/YYY')}
          </div>
        )}
        <div className='my-auto ml-3 w-[5%] min-md:hidden'>
          <Icon.ArrowRight />
        </div>
      </div>
    </div>
  );
};

export default AuthSettingsItem;
