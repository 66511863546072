import * as Icon from '../../components/icons';

const DashboardAppsComponent = () => {
  return (
    <>
      <div className='flex'>
        <div
          className='mr-8 flex h-32 w-60 cursor-pointer rounded-lg border border-[#DCE2EC] bg-white max-md:relative'
          onClick={() =>
            (window.location.href = `${import.meta.env.VITE_SKEZIA_FRONT_URL}/login?accessToken=${localStorage.getItem(
              'accessToken',
            )}&refreshToken=${localStorage.getItem('refreshToken')}`)
          }
        >
          <Icon.Skezia className='m-auto' />
          <div className='absolute bottom-3 left-[1.1rem] border-b border-[#21577A] text-[#21577A] min-md:hidden'>
            Accéder à la solution
          </div>
        </div>
        <div
          className='flex h-32 w-60 cursor-pointer rounded-lg border border-[#DCE2EC] bg-white max-md:relative'
          onClick={() =>
            (window.location.href = `${import.meta.env.VITE_SKEMEET_FRONT_URL}/p/patients?accessToken=${localStorage.getItem(
              'accessToken',
            )}&refreshToken=${localStorage.getItem('refreshToken')}`)
          }
        >
          <Icon.Skemeet className='m-auto' />
          <div className='absolute bottom-3 left-[1.1rem] border-b border-[#21577A] text-[#21577A] min-md:hidden'>
            Accéder à la solution
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAppsComponent;
