import { createInstance, MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';
import React, { FC, PropsWithChildren } from 'react';

const instance = createInstance({
  urlBase: 'https://matomo.skezi.eu',
  siteId: 15,
});

const Provider: FC<PropsWithChildren> = ({ children }) => {
  //@ts-ignore
  return <MatomoProvider value={instance}> {children}</MatomoProvider>;
};

const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const { enableLinkTracking } = useMatomo();

  enableLinkTracking();

  return <>{children}</>;
};

export const Matomo = Object.assign(
  {},
  {
    Provider,
    Wrapper,
  },
);
