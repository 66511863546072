import React, { ReactNode } from 'react';

interface AuthTabPageHeaderProps {
  title: string;
  description: string | ReactNode;
}

const AuthSettingsItemHeader = ({ title, description }: AuthTabPageHeaderProps) => {
  return (
    <div className='pb-8'>
      <div className='pb-2.5 text-xl font-bold text-[#21577A]'>{title}</div>
      <div className='border-b border-[#DCE2EC] pb-8 text-[#21577A]'>{description}</div>
    </div>
  );
};

export default AuthSettingsItemHeader;
