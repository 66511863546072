import { UserCreate, UserInfo } from '../constants/types';
import { request } from './fetcher';
export default class UsersService {
  async createUser(user: UserCreate): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/create`,
      data: {
        email: user.email,
        nickName: user.nickName,
        password: user.password,
        subscribeNewsletter: user.subscribeNewsletter,
        locationUrl: window.location.href,
      },
    });
  }

  async getUserData(): Promise<UserInfo> {
    return await request({
      method: 'get',
      url: '/users/me',
    });
  }

  async sendEmailValidation(email: string, appToken: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/send-email-validation`,
      data: {
        email,
        appToken,
      },
    });
  }

  async sendEmailMergeValidation(email: string, appToken: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/send-email-merge-validation`,
      data: {
        email,
        appToken,
      },
    });
  }

  async validateAccount(updateEmailToken: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/validate-account`,
      data: { updateEmailToken },
    });
  }

  async updateEmail(data: { email: string }) {
    return await request({
      method: 'post',
      url: '/users/update-email',
      data,
    });
  }

  async updateNickname(data: { nickName: string }) {
    return await request({
      method: 'post',
      url: '/users/update-nickname',
      data,
    });
  }

  async updatePassword(data: { oldPassword: string; newPassword: string }) {
    return await request({
      method: 'patch',
      url: `/users/password`,
      data,
    });
  }

  async updateDoubleAuthentication(oneTimePasswordActivated: boolean, cellphone: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/update-double-authentication`,
      data: {
        oneTimePasswordActivated,
        cellphone,
      },
    });
  }

  async sendSupportMessage(name: string, email: string, description: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/send-support-message`,
      data: {
        name,
        email,
        description,
      },
    });
  }
}
