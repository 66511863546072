import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Seo } from '../../components/common/Seo';
import * as Icon from '../../components/icons';

const LogoutComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    navigate(`/login`);
  }, []);

  return (
    <>
      <div className='flex h-screen items-center justify-center'>
        <Icon.Spin className='w-[6vw]' />
      </div>
      <Seo title={'Inscription'} />
    </>
  );
};

export default LogoutComponent;
