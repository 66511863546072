import { FC } from 'react';
import * as Icon from '../icons';

interface ConnexionFormHeaderProps {
  topLabel?: string;
  actionTopLabel?: string;
  actionTopOnClick?: () => void;
  title: string;
  subTitle?: string;
  subSubTitle?: string;
  proSanteConnect?: boolean;
  franceConnect?: boolean;
  getProSanteConnectAuthenticationUrl?: () => void;
  getFranceConnectAuthenticationUrl?: () => void;
}

const FormHeader: FC<ConnexionFormHeaderProps> = ({
  topLabel,
  actionTopLabel,
  actionTopOnClick,
  title,
  subTitle,
  subSubTitle,
  proSanteConnect = false,
  franceConnect = false,
  getProSanteConnectAuthenticationUrl,
  getFranceConnectAuthenticationUrl,
}) => {
  const fromSkemeet = localStorage.getItem('redirectUrl')?.includes(import.meta.env.VITE_SKEMEET_FRONT_URL);

  return (
    <div className='flex flex-col gap-8'>
      {topLabel && (
        <div className='break-words rounded-lg bg-[#EFFAF5] px-7 py-2 text-center min-lg:mx-10'>
          {topLabel}&nbsp;
          <span className='cursor-pointer font-bold text-green-dark hover:underline' onClick={actionTopOnClick}>
            {actionTopLabel}
          </span>
        </div>
      )}
      <div className='text-center text-2xl font-bold text-green-dark'>{title}</div>
      {subTitle && <div className='mb-4 text-center text-black-light'>{subTitle}</div>}
      {subSubTitle && <div className='text-center text-gray-primary'>{subSubTitle}</div>}
      {(proSanteConnect || franceConnect) && (
        <>
          <div className='flex justify-center max-lg:flex-col'>
            {proSanteConnect && (
              <div className='flex flex-col max-lg:mb-4 max-lg:flex-row max-lg:justify-center'>
                <button
                  className='w-56'
                  onClick={() => {
                    if (getProSanteConnectAuthenticationUrl) {
                      getProSanteConnectAuthenticationUrl();
                    }
                  }}
                >
                  <Icon.ProSanteConnect className='mr-2' />
                </button>
              </div>
            )}
            {franceConnect && !fromSkemeet && (
              <div className='flex flex-col'>
                <button
                  className='flex justify-center'
                  onClick={() => {
                    if (getFranceConnectAuthenticationUrl) {
                      getFranceConnectAuthenticationUrl();
                    }
                  }}
                >
                  <Icon.FranceConnect />
                </button>
                <div className='mb-6 mt-1 flex cursor-pointer justify-center text-[#000091] decoration-1 hover:decoration-2'>
                  <a
                    className='flex border-b-[1px] border-[#000091] hover:border-b-2'
                    href='https://franceconnect.gouv.fr/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Qu’est-ce que FranceConnect ?
                    <Icon.FranceConnectWhat className='ml-1 mt-1' />
                  </a>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className='border-b-2' />
            <div className='flex justify-center'>
              <span className='relative bottom-3.5 bg-white px-4 font-bold text-gray-primary'>ou</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FormHeader;
